import React, { useState } from 'react';
import Lottie from 'react-lottie';
import emailSuccessAnimation from '../../images/email-success.json';
import emailErrorAnimation from '../../images/email-error.json';
import './style.css';
import LayoutInfoPage from '../../components/layoutInfoPage';
import ContactForm from '../../components/contactForm';

const Contact = () => {
  const initialState = 'empty';
  const [formState, setFormState] = useState(initialState);

  const onSubmit = values => {
    fetch(`${process.env.GATSBY_API_URL}/send-email?captcha=${values.captcha}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    })
      .then((res) => res.status >= 400 ? Promise.reject() : res.json)
      .then(() => {
        setFormState('success');
      })
      .catch((error) => {
        console.log(error);
        setFormState('error');
      });
  };

  const retry = () => setFormState(initialState)

  const renderForm = () => <ContactForm onSubmit={onSubmit} />;

  const defaultAimationOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const renderSuccessMessage = () => (
    <div className="message-email-container">
      <Lottie
        options={{ ...defaultAimationOptions, animationData: emailSuccessAnimation }}
        height={200}
        width={200}
      />
      <h3>Missatge enviat correctament!</h3>
      <p>Ens posarem en contacte el més àviat possible.</p>
      <div className="info">
        <p>Ctra. de Cornellà, 128</p>
        <p>08950 Esplugues de Llobregat - BARCELONA</p>
        <p>Tel/Fax (34) 93 473 86 14</p>
        <p>jcr@jcr1.com</p>
      </div>
    </div>
  )

  const renderErrorMessage = () => (
    <div className="message-email-container error">
      <Lottie
        options={{ ...defaultAimationOptions, animationData: emailErrorAnimation }}
        height={200}
        width={200}
      />
      <h3>No s'ha pogut enviar el missatge</h3>
      <h4>Ho sentim, hi ha hagut algun error.</h4>
      <button onClick={retry}>Torna-ho a intentar</button>
      <div className="info">
        <p>Ctra. de Cornellà, 128</p>
        <p>08950 Esplugues de Llobregat - BARCELONA</p>
        <p>Tel/Fax (34) 93 473 86 14</p>
        <p>jcr@jcr1.com</p>
      </div>
    </div>
  )


  return (
    <div className="contacta_container">
      <LayoutInfoPage title="Contacta">
        {formState === 'empty' && renderForm()}
        {formState === 'success' && renderSuccessMessage()}
        {formState === 'error' && renderErrorMessage()}
      </LayoutInfoPage>
    </div >
  );
};

export default Contact;
