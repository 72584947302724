import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Modal } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import { formItemLayout, buttonItemLayout, tailFormItemLayout } from './formLayout';
import './style.css';

const ContactForm = ({ onSubmit }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOkModal = () => {
    form.setFieldsValue({ agreement: true })
    setIsModalVisible(false);
  };

  const handleCancelModal = () => {
    form.setFieldsValue({ agreement: false })
    setIsModalVisible(false);
  };

  const onChangeCaptcha = (value) => {
    form.setFieldsValue({ captcha: value });
  }

  return (
    <Form
      {...formItemLayout}
      style={{ width: '100%' }}
      name="contact"
      form={form}
      onFinish={onSubmit}
      scrollToFirstError
    >
      <Form.Item
        name="company"
        label="Institució"
        rules={[
          {
            required: true,
            message: 'Camp obligatori',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="role" label="Càrrec">
        <Input />
      </Form.Item>
      <Form.Item name="adress" label="Adreça">
        <Input />
      </Form.Item>
      <Form.Item name="cp" label="C.P.">
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        label="Nom"
        rules={[
          {
            required: true,
            message: 'Camp obligatori',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="surname"
        label="Cognoms"
        rules={[
          {
            required: true,
            message: 'Camp obligatori',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'E-mail no vàlid',
          },
          {
            required: true,
            message: 'Camp obligatori',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="mobile" label="Telèfon">
        <Input />
      </Form.Item>
      <Form.Item name="fax" label="Fax">
        <Input />
      </Form.Item>
      <Form.Item
        name="order"
        label="Missatge"
        rules={[
          {
            required: true,
            message: 'Camp obligatori',
          },
        ]}
      >
        <Input.TextArea rows={8} />
      </Form.Item>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) => (
              value ? Promise.resolve() : Promise.reject(new Error('Hauria d\'acceptar la política'))
            ),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          He llegit i accepto la <button className="agreement-btn" onClick={showModal}>política de privacitat</button>.
        </Checkbox>
      </Form.Item>
      <Modal title="INFORMACIÓ SOBRE PROTECCIÓ DE DADES" visible={isModalVisible} onOk={handleOkModal} onCancel={handleCancelModal}>
        <p>Finalitat: Prestació de servei, comunicacions administratives i/o comercials.</p>
        <p>Legitimació: Execució del contracte, internés legítim i consentiment.</p>
        <p>Destinataris: No es cediran dades a tercers exceptuant per obligació legal.</p>
        <p>Drets: Accés, rectificació, supressió, oposició i portabilitat de les dades.</p>
      </Modal>
      <Form.Item
        name="captcha"
        rules={[
          {
            required: true,
            message: 'Validació obligatoria',
          },
        ]}
        {...tailFormItemLayout}
      >
        <ReCAPTCHA
          sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
          onChange={onChangeCaptcha}
        />
      </Form.Item>
      <Form.Item {...buttonItemLayout}>
        <Button type="primary" htmlType="submit">
          Envia
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ContactForm;